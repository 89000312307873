export default [
  {
    path: "/",
    component: () => import("../views/Home.vue"),
    name: "Home",
    meta: {name: "Home", title: "홈"},
  },
    {
        path: "/intro",
        component: () => import("../views/Intro.vue"),
        name: "Intro",
        meta: {name: "Intro", title: "인트로"},
    },
    {
        path: "/solution",
        component: () => import("../views/Solution.vue"),
        name: "Solution",
        meta: {name: "Solution", title: "솔루션"},
    },
    {
      path: "/contact",
      component: () => import("../views/Contact.vue"),
      name: "Contact",
      meta: {name: "Contact", title: "컨텍트"},
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/",
  }
];
