<template>
    <div style="display: contents">
        <div class="cursor" id="cursor"></div>
        <transition name="fade">
            <router-view/>
        </transition>

    </div>
</template>

<script>
import MobileDetect from "mobile-detect";
export default {
    name: "App",
    mixins: [],
    components: {},
    provide() {
        return {}
    },
    props: {},
    data() {
        return {

        }
    },
    created() {

        let md = new MobileDetect(window.navigator.userAgent);
        if (md.mobile() !== null) {
            location.href = "http://m.aquanuri.io";
        }
    },
    mounted() {
    },
    beforeUnmount() {
    },
    unmounted() {
    },
    computed: {},
    methods: {},
    watch: {},
}
</script>

<style lang="scss">
@import "./assets/scss/index.scss";

</style>
