import {localStorageKey} from "@/api/config";
import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import _ from "lodash";
import Api from "@/api";
import StoragePlugin from 'vue-web-storage';
import {vfmPlugin} from "vue-final-modal";
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import ko from "element-plus/dist/locale/ko"
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import $ from 'jquery';
import VueGoogleMaps from '@fawmi/vue-google-maps'

import 'vue-fullpage.js/dist/style.css'

import VueFullPage from 'vue-fullpage.js'

import {configure} from 'vee-validate';

configure({
    validateOnBlur: false,
    validateOnChange: true,
    validateOnInput: true,
    validateOnModelUpdate: true,
});
window.$ = $
window.global = window;
let app = createApp(App);
app.use(VueFullPage)
app.use(router)
app.use(vfmPlugin)
app.use(StoragePlugin, {
    prefix: localStorageKey,
    drivers: ['local'],
})

app.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyAfNBAR9uoxd7W9fMgbx7aXGr8BobmRr8w',
    },
})


app.config.globalProperties.$api = Api;
app.config.globalProperties.$_ = _;

app.use(ElementPlus, {
    locale: ko
})
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
app.use(store)
app.mount('#app')
